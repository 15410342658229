function SupportGroup (api) {
    return {
        async list () {
            const response = await api.get('/support-groups')
            return response.data
        },

        async create (payload) {
            const response = await api.post('/support-groups', payload)
            return response.data
        },

        async update (id, payload) {
            const response = await api.put(`/support-groups/${id}`, payload)
            return response.data
        },
        async delete (id) {
            const response = await api.delete(`/support-groups/${id}`)
            return response.data
        },
    }
}

module.exports = SupportGroup
