const axios = require('axios')
const Cookie = require('js.cookie')
const Auth = require('@/api/Auth')
const User = require('@/api/User')
const UserPlan = require('@/api/UserPlan')
const Role = require('@/api/Role')
const FoodGroup = require('@/api/FoodGroup')
const Food = require('@/api/Food')
const FoodGroupHeaders = require('@/api/FoodGroupHeaders')
const SupportGroup = require('@/api/SupportGroup')
const Notification = require('@/api/Notification')
const Schedule = require('@/api/Schedule')
const Access = require('@/api/Access')

const API_URL = 'https://api.nutrireclinic.com'
// const API_URL = 'http://localhost:3000'
const Api = axios.create({
    baseURL: API_URL,
})

Api.interceptors.request.use((req) => {
    const cookie = Cookie.get('jwt')
    if (cookie) {
        req.headers.Authorization = `Bearer ${cookie}`
    }
    return req
})
const api = {
    Auth: Auth(Api),
    User: User(Api),
    UserPlan: UserPlan(Api),
    Role: Role(Api),
    FoodGroup: FoodGroup(Api),
    Food: Food(Api),
    FoodGroupHeaders: FoodGroupHeaders(Api),
    SupportGroup: SupportGroup(Api),
    Notification: Notification(Api),
    Schedule: Schedule(Api),
    Access: Access(Api),
}

module.exports = api
