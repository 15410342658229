<template>
  <v-scroll-y-transition
    mode="out-in"
    appear
  >
    <router-view />
  </v-scroll-y-transition>
</template>

<script>
  export default {
    name: 'App',
  }
</script>

<style>
  #app, .swal2-content, .swal2-actions {
    font-family: 'Josefin Sans', sans-serif;
    font-size: 16px!important;
  }

  .font-title, .swal2-title {
    font-family: 'Just Another Hand', cursive;
    font-size: 42px!important;
    font-weight: 300!important;
  }

  .swal2-styled.swal2-confirm {
    background-color: #d35c4f!important;
  }

  .color-carb {
    color: #ff6d0e!important;
  }

  .color-lact {
    color: #63b5cc!important;
  }

  .color-prot {
    color: #830b83!important;
  }

  .color-veg {
    color: #4ac781!important;
  }

  .color-fruit {
    color: #ff0908!important;
  }

  .color-fat {
    color: #9d3a0e!important;
  }

  .color-postre {
    color: #ff0908!important;
  }

</style>
