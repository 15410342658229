function Auth (api) {
    return {
        async login (payload) {
            const response = await api.post('/auth/login', payload)
            return response.data
        },

        async register (payload) {
            const response = await api.post('/auth/register', payload)
            return response.data
        },

        async forgotPassword (payload) {
            const response = await api.post('/auth/forgot-password', payload)
            return response.data
        },

        async resetPassword (payload) {
            const response = await api.post('/auth/reset-password', payload)
            return response.data
        },
    }
}

module.exports = Auth
