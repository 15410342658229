function User (api) {
    return {
        async list () {
            const response = await api.get('/users')
            return response.data
        },

        async get (id) {
            const response = await api.get(`/users/${id}`)
            return response.data
        },

        async me () {
            const response = await api.get('/users/me')
            return response.data
        },

        async update (id, payload) {
            const response = await api.put(`/users/${id}`, payload)
            return response
        },

        async setStatus (id, status) {
            const payload = { status }
            const response = await api.put(`/users/${id}`, payload)
            return response.data
        },
    }
}

module.exports = User
