function FoodGroup (api) {
    return {
        async list () {
            const response = await api.get('/food-groups')
            return response.data
        },

        async create (payload) {
            const response = await api.post('/food-groups', payload)
            return response.data
        },

        async update (id, payload) {
            const response = await api.put(`/food-groups/${id}`, payload)
            return response.data
        },
        async setStatus (id, status) {
            const payload = { status }
            const response = await api.put(`/food-groups/${id}`, payload)
            return response.data
        },
    }
}

module.exports = FoodGroup
