function Schedule (api) {
    return {
        async list () {
            const response = await api.get('/schedule/notifications')
            return response.data
        },

        async create (payload) {
            const response = await api.post('/schedule/notifications', payload)
            return response.data
        },

        async update (id, payload) {
            const response = await api.put(`/schedule/notifications/${id}`, payload)
            return response.data
        },

        async remove (id) {
            const response = await api.delete(`/schedule/notifications/${id}`)
            return response.data
        },

        async setStatus (id, status) {
            const payload = { status }
            const response = await api.put(`/schedule/notifications/${id}`, payload)
            return response.data
        },
    }
}

module.exports = Schedule
