import Vue from 'vue'
import Router from 'vue-router'
import Cookies from 'js.cookie'

Vue.use(Router)

const router = new Router({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: [
    {
      path: '/login',
      component: () => import('@/views/dashboard/pages/Login'),
    },
    {
      path: '/logout',
      component: () => import('@/views/dashboard/pages/Logout'),
    },
    {
      path: '/confirm-email',
      component: () => import('@/views/dashboard/pages/ConfirmEmail'),
    },
    {
      path: '/forgot-password',
      component: () => import('@/views/dashboard/pages/ForgotPassword'),
    },
    {
      path: '/reset-password',
      component: () => import('@/views/dashboard/pages/ResetPassword'),
    },
    {
      path: '/',
      component: () => import('@/views/dashboard/Index'),
      children: [
        // Dashboard
        {
          name: 'Dashboard',
          path: '',
          meta: { requireAuth: true },
          component: () => import('@/views/dashboard/Dashboard'),
        },
        // Pages
        {
          name: 'Usuarios',
          path: '/users',
          meta: { requireAuth: true },
          component: () => import('@/views/dashboard/pages/User'),
        },
        {
          name: 'Planes',
          path: '/plans',
          meta: { requireAuth: true },
          component: () => import('@/views/dashboard/pages/Plans'),
        },
        {
          name: 'Grupos Alimenticios',
          path: '/food-groups',
          meta: { requireAuth: true },
          component: () => import('@/views/dashboard/pages/FoodGroups'),
        },
        {
          name: 'Alimentos',
          path: '/foods',
          meta: { requireAuth: true },
          component: () => import('@/views/dashboard/pages/Foods'),
        },
        {
          name: 'Notificaciones Push',
          path: '/notifications',
          meta: { requireAuth: true },
          component: () => import('@/views/dashboard/pages/PushNotifications'),
        },
        {
          name: 'App Headers',
          path: '/headers',
          meta: { requireAuth: true },
          component: () => import('@/views/dashboard/pages/Headers'),
        },
        {
          name: 'Grupos de Apoyo',
          path: '/support-groups',
          meta: { requireAuth: true },
          component: () => import('@/views/dashboard/pages/SupportGroups'),
        },
        {
          name: 'Programar Notificaciones',
          path: '/schedule',
          meta: { requireAuth: true },
          component: () => import('@/views/dashboard/pages/Schedule'),
        },
        {
          name: 'Acceso Mensual',
          path: '/monthly-access',
          meta: { requireAuth: true },
          component: () => import('@/views/dashboard/pages/MonthlyAccess'),
        },
      ],
    },
  ],
})

router.beforeEach((to, from, next) => {
  const jwt = Cookies.get('jwt')
  if (to.meta.requireAuth) {
    if (jwt) {
      try {
        const data = JSON.parse(atob(jwt.split('.')[1]))
        const expiration = new Date(data.exp * 1000)
        const now = new Date()
        if (now > expiration) {
          next('/login')
        } else {
          next()
        }
      } catch (error) {
        next('/login')
      }
    } else {
      next('/login')
    }
  } else {
    next()
  }
})

export default router
