function Role (api) {
    return {
        async list () {
            const response = await api.get('/roles')
            return response.data
        },

        async get (id) {
            const response = await api.get(`/roles/${id}`)
            return response.data
        },

        async update (id, payload) {
            const response = await api.put(`/roles/${id}`, payload)
            return response.data
        },

    }
}

module.exports = Role
