function Access (api) {
    return {
        async onSave (payload) {
            const response = await api.post('/passwordapp', payload)
            return response
        },
        async update (payload, id) {
            const response = await api.put(`/passwordapp/${id}`, payload)
            return response
        },
        async get () {
            const response = await api.get('/passwordapp')
            return response
        },

    }
}

module.exports = Access
