function FoodGroupHeaders (api) {
    return {
        async create (payload) {
            const response = await api.post('/images/food-groups', payload)
            return response.data
        },
    }
}

module.exports = FoodGroupHeaders
