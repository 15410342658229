function UserPlan (api) {
    return {
        async list (params = null) {
            const response = await api.get('/user-plans', { params })
            return response.data
        },

        async get (id) {
            const response = await api.get(`/user-plans/${id}`)
            return response.data
        },

        async create (payload) {
            const response = await api.post('/user-plans', payload)
            return response.data
        },

        async update (id, payload) {
            const response = await api.put(`/user-plans/${id}`, payload)
            return response.data
        },

        async setStatus (id, status) {
            const payload = { status }
            const response = await api.put(`/user-plans/${id}`, payload)
            return response.data
        },
    }
}

module.exports = UserPlan
