function Food (api) {
    return {
        async list () {
            const response = await api.get('/foods')
            return response.data
        },

        async create (payload) {
            const response = await api.post('/foods', payload)
            return response.data
        },

        async update (id, payload) {
            const response = await api.put(`/foods/${id}`, payload)
            return response.data
        },
        async setStatus (id, status) {
            const payload = { status }
            const response = await api.put(`/foods/${id}`, payload)
            return response.data
        },
    }
}

module.exports = Food
