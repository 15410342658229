import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    barColor: 'rgba(0, 0, 0, .8), rgba(0, 0, 0, .8)',
    barImage: require('@/assets/img/sidebar.png'),
    drawer: null,
    apiUrl: process.env.VUE_APP_API_URL,
    username: null,
    userId: null,
  },
  mutations: {
    SET_BAR_IMAGE (state, payload) {
      state.barImage = payload
    },
    SET_DRAWER (state, payload) {
      state.drawer = payload
    },
    SET_USER (state, payload) {
      state.username = payload.username
      state.userId = payload.id
    },
    SESSION_DESTROY (state) {
      state.username = null
      state.userId = null
    },
  },
  actions: {

  },
})
