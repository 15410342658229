function Notification (api) {
    return {
        async send (payload) {
            const response = await api.post('/notifications/send', payload)
            return response.data
        },

    }
}

module.exports = Notification
